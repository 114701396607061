<template>
  <div class="wap-my">
    <div class="top">
      <div class="flex-center">
        <div
          class="user-header my"
          :class="`header-${avatar}`"
          @click="toPath('wapAvatar')"
        ></div>
        <div class="flex-1 info" v-if="isLogin">
          <div class="name">
            {{ userName }}
          </div>
          <div class="flex-center-start user-id">
            ID: {{ userId }}
            <i class="el-icon-copy-document copy-icon" @click="copyData"></i>
          </div>
        </div>
        <div class="flex-1 info big" v-else @click="toPath('wapUserLogin')">
          {{ $t('deng-lu-zhu-ce') }}
        </div>
      </div>

      <div class="total-count flex-center">
        <div class="box" @click="toPath('wapCollect')">
          <div class="count">{{ goodsList.length }}</div>
          <div class="label">{{ $t('shang-pin-shou-cang-0') }}</div>
        </div>
        <div class="box" @click="toPath('wapFollowShop')">
          <div class="count">{{ shopCount }}</div>
          <div class="label">{{ $t('guan-zhu-dian-pu-0') }}</div>
        </div>
      </div>
    </div>

    <div class="my-main">
      <div class="money-box flex-column-center">
        <div class="label flex-center">
          {{ $t('zhang-hu-yueusd') }}
          <img
            :src="showMoney ? eyeUrl2 : eyeUrl"
            alt=""
            @click="showMoney = !showMoney"
          />
        </div>
        <div class="count">{{ showMoney ? userMoney.toFixed(2) : '****' }}</div>
        <div class="btn flex-center-between">
          <Button @click="toPath('wapUserWithdraw')">{{
            $t('ti-xian')
          }}</Button>
          <Button
            class="primary"
            @click="toPath('wapRecharge', { type: 'user' })"
            >{{ $t('chong-zhi') }}</Button
          >
        </div>
      </div>

      <div class="order-box">
        <div class="flex-center-between label" @click="toOrder('')">
          <div>{{ $t('wo-de-ding-dan') }}</div>
          <div class="flex-center more">
            {{ $t('quan-bu') }} <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="flex-center menu-list">
          <div
            class="menu flex-column-center"
            v-for="(item, i) in menuList"
            :key="i"
            @click="toOrder(item.status)"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>

      <div class="login-box flex-center-between" @click="toSeller">
        <div class="flex-center">
          <img :src="loginUrl" alt="" />
          <div>
            {{
              isBusiness == 1 ? $t('shang-jia-deng-lu') : $t('shang-jia-ru-zhu')
            }}
          </div>
        </div>
        <i class="el-icon-arrow-right"></i>
      </div>

      <div class="credit-box flex-center-between" @click="toPath('wapCredit')">
        <div class="flex-center">
          <img :src="creditUrl" alt="" />
          <div>{{ $t('dai-kuan-shen-qing-0') }}</div>
        </div>
        <i class="el-icon-arrow-right"></i>
      </div>

      <div class="menu-box">
        <div
          class="list flex-center-between"
          v-for="(item, i) in menuList2"
          :key="i"
          @click="toPath(item.path)"
        >
          <div class="flex-center">
            <img :src="item.icon" alt="" />
            {{ item.name }}
          </div>
          <div class="flex-center">
            <span v-if="item.desc">{{ item.desc }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <footerBar></footerBar>

    <div class="kefu-icon flex-center" @click="toPath('wapKefu')">
      <i class="el-icon-service"></i>
    </div>
  </div>
</template>
<script>
import { Button, Popup, Icon } from 'vant'
import Clipboard from 'clipboard'
import footerBar from '@/components/footer/wapFooter'
import { getShopFollowList } from '@/api/shop'
import { userInfo, getWalletInfo } from '@/api/user'
export default {
  name: 'about',
  components: {
    Button,
    Popup,
    Icon,
    footerBar
  },
  data() {
    return {
      userName: '',
      userId: '',
      goodsList: [],
      shopList: [],
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      loginUrl: require('@/assets/imgs/icon-login.png'),
      creditUrl: require('@/assets/imgs/icon-credit4.png'),
      showMoney: true,
      userMoney: 0,
      shopCount: 0,
      isBusiness: 2
    }
  },
  computed: {
    avatar() {
      return this.$store.state.avatar
    },
    langName() {
      return this.$store.state.langName
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    menuList() {
      return [
        {
          icon: require('@/assets/imgs/icon-order1.png'),
          status: '0',
          name: this.$t('dai-fu-kuan')
        },
        {
          icon: require('@/assets/imgs/icon-order2.png'),
          status: '1',
          name: this.$t('dai-fa-huo')
        },
        {
          icon: require('@/assets/imgs/icon-order3.png'),
          status: '2',
          name: this.$t('dai-shou-huo')
        },
        {
          icon: require('@/assets/imgs/icon-order4.png'),
          status: '3',
          name: this.$t('dai-ping-jia')
        },
        {
          icon: require('@/assets/imgs/icon-order5.png'),
          status: '7,8',
          name: this.$t('tui-kuan-shou-hou')
        }
      ]
    },
    menuList2() {
      return [
        {
          icon: require('@/assets/imgs/icon-menu2.svg'),
          name: this.$t('yu-yan'),
          path: 'wapLang',
          desc: this.langName
        },
        {
          icon: require('@/assets/imgs/icon-menu6.svg'),
          name: this.$t('she-zhi'),
          path: 'wapMySetting'
        }
      ]
    }
  },
  mounted() {
    if (this.isLogin) {
      this.init()
    }
  },
  methods: {
    init() {
      getShopFollowList({}).then((res) => {
        this.shopCount = res.data.Pagination.totalRecords
      })

      userInfo().then((res) => {
        let data = res.data
        this.userName = data.Phone || data.Email
        this.userId = data.ID
        this.isBusiness = data.IsBusiness
      })

      // 余额
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    copyData() {
      let data = this.userId
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$toast(this.$t('fu-zhi-cheng-gong'))
    },
    toPath(path, query) {
      this.$router.push({
        name: path,
        query
      })
    },
    toOrder(status) {
      this.$router.push({
        name: 'wapUserOrder',
        query: {
          status: status
        }
      })
    },
    toSeller() {
      if (this.isBusiness === 1) {
        let name = this.isLogin ? 'wapHome' : 'wapLogin'
        this.$router.push({
          name: name
        })
      } else {
        let name = this.isLogin ? 'wapOpenShop' : 'wapUserLogin'
        this.$router.push({
          name: name
        })
      }
    }
  }
}
</script>
<style lang="less">
</style>
